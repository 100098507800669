<template>
  <div class="ml-5 mr-5 mt-10 secondary">
    <v-row
      class="ml-5 pt-3"
      v-if="showtop"
    >
      <v-tooltip
        v-if="!showtypes"
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="addDoc()"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >add_box</v-icon>
            <span class="accent--text text-lowercase text-h6">Add new species</span>
          </v-btn>
        </template>
        <span>Add new species</span>
      </v-tooltip>
      <v-tooltip
        v-if="!showtypes"
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="selectItem('name', 'Enter any part of name')"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >search</v-icon>
            <span class="accent--text text-lowercase text-h6">Name</span>
          </v-btn>
        </template>
        <span>Search by Name</span>
      </v-tooltip>
      <v-tooltip
        v-if="showtypes"
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="showtypes = false, cur_name = ''"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >mdi-close-box</v-icon>
            <span class="accent--text text-lowercase text-h6">Close Photos</span>
          </v-btn>
        </template>
        <span>Close Photos</span>
      </v-tooltip>
      <p
        v-if="!showtypes && nores != 'No Results'"
        class="title font-weight-black accent--text"
      >
        Total species: {{ total }}
      </p>
      <p
        v-if="search || showtypes"
        class="ml-5"
      >
        <span
          v-if="search"
          @click="serOff()"
          class="accent--text searchbar"
        >Search: </span>
        <span
          v-if="fld != 'favorite'"
          class="black-text font-weight-bold"
        >{{
            search.substr(0, 50)
          }}</span>
        <span
          v-if="fld == 'favorite'"
          class="black-text font-weight-bold"
        > Fav </span>
        <span
          v-if="cur_name"
          class="ml-3 accent--text searchbar"
        >Photos: </span>
        <span
          class="black-text font-weight-bold"
          v-if=cur_name
        >
          {{cur_name}}
        </span>
      </p>

    </v-row>
    <v-col
      v-if="editdoc || adddoc"
      xs12
      sm3
      md8
      class="mb-4"
    >
      <v-card class="primary">
        <v-card-title v-if="editdoc">
          <h2 class="accent--text">Edit species</h2>
        </v-card-title>
        <v-card-title v-else-if="adddoc">
          <h2 class="accent--text">Add new species</h2>
        </v-card-title>
        <v-card-text>
          <v-form
            class="px-3"
            ref="form"
            @submit.prevent="onSubmit"
          >
            <v-text-field
              label="Name"
              type="text"
              class="formedit accent--text"
              v-model.lazy="curdoc.name"
            ></v-text-field>
            <ckeditor
              :editor="editor"
              v-model.lazy="curdoc.essay"
              :config="editorConfig"
              class="ck-content"
            ></ckeditor>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              text
              @click="submit"
              class="black white--text mx-0 mt-3 mr-3"
            >Save species</v-btn>
            <v-btn
              text
              @click="
                  editdoc = false;
                  adddoc = false;
                  showtop = true;
                  dispdoc = false;
                  curdoc = [];
                "
              class="black white--text mx-0 mt-3"
            >Cancel</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <div
      :key="refresh"
      v-if="!showtypes"
      class="tablediv"
    >
      <table
        class="mytable"
        v-if="!dispdoc && !(adddoc || editdoc)"
      >
        <tbody>
          <tr>
            <th
              @click.exact="sortBy('name')"
              @click.shift.exact="sortByD('name')"
              class="title name accent--text font-weight-bold"
            >
              Name
            </th>
            <th
              @click.exact="sortBy('chk')"
              @click.shift.exact="sortByD('chk')"
              class="title name accent--text font-weight-bold"
            >
              Last Taken
            </th>
            <th
              @click.exact="sortBy('count')"
              @click.shift.exact="sortByD('count')"
              class="title accent--text font-weight-bold numbers"
            >
              Count
            </th>
            <th class="title accent--text font-weight-bold buttons">Actions</th>
          </tr>

          <tr
            v-for="project in projects"
            :key="project.id"
            row
            wrap
          >
            <td
              class="item-title item text--darken-3"
              @click="editTxt(project.id)"
            >
              {{ project.name }}
            </td>
            <td
              class="item-title item text--darken-3"
              @click="editTxt(project.id)"
            >
              {{ project.date }}
            </td>
            <td
              class="item-title item text--darken-3 numbers"
              @click="editTxt(project.id)"
            >
              {{ project.count }}
            </td>
            <td
              v-if="nores != 'No Results'"
              class="buttons"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    class="mr-4 material-icons md-48"
                    @click="editDoc(project.id)"
                    :class="{ filled: project.essay }"
                  >edit</v-icon>
                </template>
                <span>Edit species</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    color="black"
                    class="mr-4 material-icons md-48"
                    @click="showPics(project.name)"
                    :class="{ filled: project.essay }"
                  >mdi-plus-box-multiple</v-icon>
                </template>
                <span>Show Photos</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="my-2 material-icons md-48"
                    @click="delDoc(project.id)"
                  >delete</v-icon>
                </template>
                <span>Delete species</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-dialog
      v-model="dialog"
      width="70%"
    >
      <v-card
        row
        flat
        height="auto"
        class="primary"
      >
        <v-card-text class="mt-0 pt-0">
          <v-card-actions
            row
            class="primary mt-5"
          >
            <v-btn
              large
              text
              color="accent"
              @click="dialog = false"
            >
              <v-icon
                color="success"
                large
                left
              >close</v-icon>
              <span class="success--text body-1 text-lowercase">Close</span>
            </v-btn>
          </v-card-actions>
          <v-row class="primary">
            <v-col
              sm="12"
              md="12"
              lg="10"
              xl="8"
            >
              <h1 class="success--text">{{ curdoc.name }}</h1>
            </v-col>
          </v-row>
          <v-row class="primary">
            <v-col
              class="ck-content"
              sm="12"
              md="12"
              lg="10"
              xl="8"
              center
            >
              <hr class="secondary thick" />
              <div
                class="primary mt-5"
                v-html="curdoc.essay"
              ></div>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="showtypes">
      <v-flex
        sm12
        md12
        lg6
        text-center
        headline
        mt-5
        pb-5
        ml-10
      >
        <v-carousel
          transition="fade-transition"
          height="auto"
          class="img-one"
          progress-color="red"
          prev-icon="arrow_left"
          next-icon="arrow_right"
          delimiter-icon="stop"
          hide-delimiters
        >
          <v-carousel-item
            aspect-ration="4/3"
            v-for="type in types"
            :key="type.id"
            :src="picurl + type.pic"
          >
            <v-layout
              fill-height
              align-end
            >
              <v-flex xs12>
                <div
                  v-if="type.favorite == 'Y'"
                  class="tag1 white--text black arch"
                >
                  <span
                    class="pl-2 pr-2 white-text black"
                    @click="chngRef(type.id, type.favorite)"
                  >
                    Ref </span>
                </div>
                <div
                  v-if="type.favorite == 'N'"
                  @click="chngRef(type.id, type.favorite)"
                  class="tag1 ml-2 white--text black"
                >
                  <span class="pl-2 pr-2 black arch"><v-icon
                      medium
                      class="white arch"
                    >mdi-asterisk</v-icon>
                  </span>
                </div>
                <div class="tag2 white--text black text-left">
                  <span
                    @click="chngStatus(type.id, type.archive)"
                    class="pl-2 pr-2 arch"
                    :class="{ active: type.archive == 'AB', archive: type.archive == 'AC'}"
                  >
                    {{ transArch(type.archive) }} </span>
                </div>
                <div class="tag3 white--text black text-left">
                  <span
                    @click="chngRating(type.id, type.rating)"
                    class="pl-2 pr-2 arch"
                  >
                    {{type.rating}} </span>
                </div>
              </v-flex>
            </v-layout>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </div>
  </div>
</template>
  
  <script>
import { shared } from "../mixins/shared";
import store from "../store/store.js";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import LineHeight from "ckeditor5-line-height-plugin/src/lineheight";
import List from "@ckeditor/ckeditor5-list/src/list";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Font from "@ckeditor/ckeditor5-font/src/font";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";

export default {
  mixins: [shared],
  props: {
    tid: {
      type: String,
      required: true,
      default: "journal",
    },
  },
  data() {
    return {
      cur_name: "",
      dialog: false,
      showtypes: false,
      search: store.types_search,
      fld: store.types_fld,
      total: 0,
      nores: "",
      items: ["save", "publish"],
      values: ["save", "publish"],
      projects: [],
      types: [],
      refresh: 0,
      editdoc: false,
      adddoc: false,
      dispdoc: false,
      showtop: true,
      upimg: false,
      curdoc: [],
      curid: 0,
      menu: false,
      url: "https://vsgpartners.com/data.php",
      picurl: "https://vsgpartners.com/images/",
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          Underline,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          Image,
          ImageToolbar,
          ImageStyle,
          ImageResize,
          ImageUpload,
          List,
          // EasyImage,
          LineHeight,
          SimpleUploadAdapter,
          Heading,
          Alignment,
          Font,
          Strikethrough,
          Subscript,
          Superscript,
          BlockQuote,
          Indent,
          IndentBlock,
        ],
        indentBlock: {
          classes: [
            "custom-block-indent-a", // First step - smallest indentation.
            "custom-block-indent-b",
            "custom-block-indent-c", // Last step - biggest indentation.
          ],
        },
        lineHeight: {
          // specify your otions in the lineHeight config object. Default values are [ 0, 0.5, 1, 1.5, 2 ]
          options: [0.5, 1, 1.5, 1.75, 2, 2.5],
        },
        image: {
          resizeUnit: "px",
          // You need to configure the image toolbar, too, so it uses the new style buttons.
          toolbar: [
            "imageTextAlternative",
            "|",
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
          ],

          styles: [
            "alignCenter",

            // This represents an image aligned to the left.
            "alignLeft",

            // This represents an image aligned to the right.
            "alignRight",
          ],
        },
        alignment: {
          options: ["left", "right"],
        },
        fontSize: {
          options: ["tiny", "default", "big"],
        },
        toolbar: {
          items: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "subscript",
            "superscript",
            "|",
            "numberedList",
            "bulletedList",
            "alignment",
            "outdent",
            "indent",
            "|",
            "blockQuote",
            "|",
            "link",
            "|",
            "imageUpload",
            "heading",
            "|",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "lineHeight",
          ],
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,

          // Let the users control the "download" attribute of each link.
          decorators: [
            {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "download",
              },
            },
          ],
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: "https://vsgpartners.com/upload.php?CKEditorFuncNum=1",

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            "X-CSRF-TOKEN": "CSFR-Token",
            Authorization: "Bearer <JSON Web Token>",
          },
        },
      },
    };
  },
  methods: {
    submit() {
      var url = this.url;
      var db = store.durl;
      var action;
      if (this.editdoc) {
        action = "submit-b";
      } else {
        action = "add-b";
      }
      var formdata = {
        action: action,
        id: this.curdoc.id,
        tid: "types",
        name: this.curdoc.name,
        date: this.curdoc.date,
        essay: this.curdoc.essay,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {
          this.getDocs("displayb");
        })
        .catch((error) => alert(error));
      this.editdoc = false;
      this.adddoc = false;
      this.curdoc = [];
      this.dispdoc = false;
      this.showtop = true;
    },
    transArch: function (status) {
      if (status == "AB") {
        return "Active";
      }
      if (status == "AC") {
        return "Archived";
      }
    },
    chngStatus(id, curstat) {
      if (curstat == "AB") {
        curstat = "AC";
      } else if (curstat == "AC") {
        curstat = "AB";
      }
      this.archDoc(id);
      for (var i = 0; i < this.types.length; i++) {
        if (this.types[i].id === id) {
          this.types[i].archive = curstat;
        }
      }
    },
    chngRef(id, curfav) {
      if (curfav == "Y") {
        curfav = "N";
      } else if (curfav == "N") {
        curfav = "Y";
      }
      this.favDoc(id);
      for (var i = 0; i < this.types.length; i++) {
        if (this.types[i].id === id) {
          this.types[i].favorite = curfav;
        }
      }
    },
    chngRating(id, rating) {
      rating = prompt("Please enter the rating", rating);
      if (rating) {
        var url = this.url;
        var db = store.durl;
        var formdata = {
          action: "ratecomp",
          id: id,
          rating: rating,
          db: db,
        };
        this.axios
          .post(url, formdata, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((res) => {
            const data = res.data;
            for (var i = 0; i < this.types.length; i++) {
              if (this.types[i].id === id) {
                this.types[i].rating = rating;
              }
            }
          })
          .catch((error) => alert(error));
      }
    },
    archDoc(id) {
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "arch",
        tid: "photos",
        id: id,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {})
        .catch((error) => alert(error));
    },
    favDoc(id) {
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "fav",
        id: id,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {})
        .catch((error) => alert(error));
    },
    selectItem(which, cue) {
      this.fld = which;
      this.search = prompt(cue);
      store.types_search = this.search;
      store.types_fld = this.fld;
      this.saveSearch(this.search, "types", this.fld);
      this.getDocs();
    },
    saveSearch(search, sdb, fld) {
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "search-s",
        search: search,
        tid: "startup",
        sdb: sdb,
        fld: fld,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {})
        .catch((error) => alert(error));
    },
    serOff() {
      this.search = "";
      this.fld = "";
      store.types_search = this.search;
      store.types_fld = this.fld;
      this.saveSearch(this.search, "types", this.fld);
      this.getDocs();
    },
    async showPics(name) {
      this.types = [];
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "display-bp",
        fld: "name",
        search: name,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          this.types = res.data;
          this.cur_name = this.types[0].title;
          this.showtypes = true;
        })
        .catch((error) => alert(error));
    },
    editTxt(id) {
      for (var i = 0; i < this.projects.length; i++) {
        if (this.projects[i].id === id) {
          this.curdoc.essay = this.projects[i].essay;
          this.curdoc.name = this.projects[i].name;
          this.curdoc.date = this.projects[i].date;
          if (this.curdoc.essay) {
            this.dialog = true;
          }
        }
      }
    },
    editDoc(id) {
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "edit-b",
        id: id,
        tid: "types",
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.curdoc = data[0];
          this.editdoc = true;
          this.showtop = false;
          this.dispdoc = false;
        })
        .catch((error) => alert(error));
    },
    addDoc() {
      this.curdoc = [];
      this.adddoc = true;
      this.showtop = false;
      this.dispdoc = false;
    },
    delDoc(id) {
      if (confirm("Do you really want to delete?")) {
        var url = this.url;
        var db = store.durl;
        var formdata = {
          action: "del-b",
          id: id,
          tid: "types",
          db: db,
        };
        this.axios
          .post(url, formdata, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then(() => {
            this.getDocs("displayj");
          })
          .catch((error) => alert(error));
      }
    },
    getDocs() {
      this.projects = [];
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "displayb",
        search: this.search,
        fld: this.fld,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          this.projects = res.data;
          this.total = this.projects.length;
          this.nores = this.projects[0].name;
        })
        .catch((error) => alert(error));
    },
  },
  async created() {
    var url = this.url;
    var formdata = {
      action: "search-g",
      tid: "startup",
    };
    await this.axios
      .post(url, formdata, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        const data = res.data;
        store.types_search = data[1].search;
        store.types_fld = data[1].fld;
        this.search = store.types_search;
        this.fld = store.types_fld;
      })
      .catch((error) => alert(error));
    var url = this.url;
    var formdata = {
      action: "displayb",
      search: store.types_search,
      fld: store.types_fld,
      tid: this.tid,
    };
    this.axios
      .post(url, formdata, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        const data = res.data;
        this.projects = data;
        this.nores = this.projects[0].name;
        this.total = this.projects.length;
      })
      .catch((error) => alert(error));
  },
};
</script>
  
  <style scoped>
.tablediv {
  width: 70%;
}
.active {
  background-color: #8bc34a;
}
.archive {
  background-color: #2196f3;
}
.tag1 {
  float: left;
  width: 30%;
}

.tag2 {
  float: left;
  width: 20%;
}
.tag3 {
  float: left;
  width: 47%;
}
.arch:hover {
  color: orange !important;
}
.arch {
  cursor: pointer;
}
.img-one {
  border-radius: 10%;
}

.filled {
  color: red;
}

.dates {
  text-align: left;
}
</style>
  